import { SET_PRODUCTS_LIST } from '../actions/actions';



const initialState = {
  list: [],
  msg: '',
  status: ''
}

export default (state = initialState, action) => {
  const { type, list } = action;

  if (type === SET_PRODUCTS_LIST) {
    return { ...state, status: '', list }
  }
  return state;

}