import { combineReducers } from 'redux';

// reducers
import authReducer from './authReducer';
import clientReducer from './clientReducer';
import productsReducer from './productsReducer';
import orderReducer from './orderReducer';
import globalReducer from './globalReducer';


const reducers = combineReducers({
  auth: authReducer,
  client: clientReducer,
  products: productsReducer,
  order: orderReducer,
  global: globalReducer
});

export default reducers;