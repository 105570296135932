import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {setGlobalMsg} from '../../actions';

const PrivateRoute = ({order, setMsg, authData, component: Component, ...rest }) => {

  return (
    <Route {...rest} render={props => {

          // console.log('route props',props);
          if(!authData.isLogged()) {
            return <Redirect to="/" />
          }
          const {location:{pathname}} = props;
          
          // has order data?
          if(pathname !== '/home' && ! order.isValid()) {
            setMsg('Preencha os dados do pedido!','error');
            return <Redirect to="/home" />
          }
          return <Component {...props} />
        }
      }
    />
  )
}

const mapState2Props = (state) => {
  return {
    authData: state.auth.authData,
    order: state.order
  }
}

const mapDispatch2Props = (dispatch) => ({
  setMsg: (msg,sts)=>dispatch(setGlobalMsg(msg,sts))
})

export default connect(mapState2Props,mapDispatch2Props)(PrivateRoute);