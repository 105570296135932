import { SET_AUTH_DATA } from './actions';
import { setGlobalMsg } from './global';
import { doLoginService } from '../services/authService';

const setLoggedUser = (authData) => {
  return {
    type: SET_AUTH_DATA,
    authData
  }
}

const startLogin = (user, pass) => {
  return async dispatch => {
    dispatch(setGlobalMsg('', 'loading'));
    try {
      const authData = await doLoginService({ user, pass });
      // console.log('authData',authData);
      dispatch(setGlobalMsg('', ''));
      return dispatch(setLoggedUser(authData));
    } catch (e) {
      dispatch(setGlobalMsg(e.message, 'error'))
      throw e;
    }
  }
}


export {
  setLoggedUser,
  startLogin
}
