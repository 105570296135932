import React from 'react';
import { PRODUCT_IMG_URL } from '../../services/config';
import no_photo from '../../assets/imgs/no-photo.png';

import { QtdField } from '../';

import './orderlistitem.css';

const OrderListItem = ({ item, onChangeQtd, onRemove }) => {
  const { id, name, qtd, qtdDisp } = item;
  let { img } = item;
  if (!img) {
    img = no_photo;
  } else {
    img = PRODUCT_IMG_URL + img;
  }

  const onChange = ({ target: { value } }) => {
    return onChangeQtd(id, value);
  }

  const removing=()=>{
    return onRemove(id);
  }

  return (
    <div className="order-list-item container-padding">
      <img src={img} alt={name} />
      <div className="center-item">
        <p>{name}</p>
        <QtdField onChange={onChange} value={qtd} max={qtdDisp} />
        <small>(max: {qtdDisp})</small>
      </div>
      <button onClick={removing} className="btn-default">Excluir</button>
    </div>
  )
}


export default OrderListItem;