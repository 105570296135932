import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import './nomatch.css';

const NoMatch = ({ authData, history:{location} }) => {

  if (!authData.isLogged()) {
    return <Redirect to="/" />
  }

  return (
    <div className="route-no-match">
      <h2>A URL <em>{location.pathname}</em> não existe! <br /><small>Use o menu abaixo para acessar o sistema.</small></h2>
    </div>
  )
}

const mapState2Props = state => ({
  authData: state.auth.authData
})

export default connect(mapState2Props)(NoMatch);