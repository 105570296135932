import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { TextField, Loading, ProductListItem } from '../../components';
import { searchProduct, setProductsList } from '../../actions';
import './productlist.css';

const ProductList = ({
  list,
  status,
  search,
  setList
}) => {

  const products = list.map(elem => <ProductListItem key={elem.id} product={elem} />);

  const inputRef = React.createRef();

  const [searchInput, setSearchInput] = useState('');
  const [listMsg, setListMsg] = useState('Realize uma busca');


  useEffect(() => {
    setListMsg((status === 'loading' ? 'Buscando...' : 'Nenhum produto encontrado!'));
  }, [status]);

  useEffect(() => {
    setList([]);
    inputRef.current.focus();
    setListMsg('Digite um nome de produto ou parte do nome.');
  }, []);

  const onChange = fnc => ({ target }) => {
    return fnc(target.value);
  }

  const onSearch = async () => {
    setListMsg('Buscando...');
    await search({ descr: searchInput });
  }
  const handleKeyPress = async(e) => {
    if (e.key === "Enter") {
      setListMsg('Buscando...');
      await search({ descr: searchInput });
    }
  }

  return (
    <div className="products-list container container-padding color-ritz">
      <div className="search">
        <TextField
          placeholder="Nome do produto"
          value={searchInput}
          ref={inputRef}
          onChange={onChange(setSearchInput)}
          onKeyUp={handleKeyPress}
        />
        <i className="icon-search" onClick={onSearch}></i>
      </div>
      <div className="products-found">
        <Loading status={status} />
        {products.length ?
          products :
          <h3 className="default-msg1">{listMsg}</h3>
        }
      </div>
    </div>
  )
}

const mapState2Props = ({ products, global }) => ({
  list: products.list,
  status: global.status,
});

const mapDispatch2Props = (dispatch) => ({
  search: ({ descr, id }) => dispatch(searchProduct({ descr, id })),
  setList: (descr) => dispatch(setProductsList(descr)),
});

export default connect(mapState2Props, mapDispatch2Props)(ProductList);