import React from 'react';

import logo from '../../assets/imgs/ritz-logo1.png';
import logo_escrito from '../../assets/imgs/ritz-logo-escrito.png';

import './headerritz.css';

const HeaderRitz = () => {

  return (
    <header className="header-ritz">
      <img src={logo} alt="Ritz logo" />
      <img src={logo_escrito} alt="Ritz logo" />
    </header>
  )
}

export default HeaderRitz;