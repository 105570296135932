import { saveLocalStorage, loadLocalStorage } from '../services/localStorage';
/*
order: {
  list: [orderItem]
  client: String
  eventDate: String
  deliveryDate: String
  withdrawal: String
  clientType: Int
}
*/

export default (orderData = {}) => {
  const { order: {
    list: savedList,
    client: savedClient,
    eventDate: savedEventDate,
    deliveryDate: savedDeliveryData,
    withdrawal: savedWithdrawal,
    clientType: savedClientType
  } } = loadLocalStorage();
  const {
    list = savedList,
    client = savedClient,
    eventDate = savedEventDate,
    deliveryDate = savedDeliveryData,
    withdrawal = savedWithdrawal,
    clientType = savedClientType,
  } = orderData;


  // saving back



  const newOrder = {
    list,
    client,
    eventDate,
    deliveryDate,
    withdrawal,
    clientType,
    saveOrderData: function () {
      const toSave = {
        order: this
      }
      saveLocalStorage(toSave);
    },
    clearOrderData: function () {
      this.list = [];
      this.client = '';
      this.eventDate = '';
      this.deliveryDate = '';
      this.withdrawal = '';
      this.clientType = '';
      this.saveOrderData();
    },
    isValid: function() {
      const {client,eventDate,deliveryDate}=this;
      // console.log('order - isValid()',{client,eventDate,deliveryDate});
      if(!client || !eventDate || !deliveryDate || !withdrawal) {
        return false;
      }
      return true;
    }
  }

  if(!newOrder.list) {
    newOrder.list=[];
  }

  // console.log('111 ORDER',newOrder)

  newOrder.saveOrderData();

  return newOrder;

}