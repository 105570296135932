import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { addOrderItem } from '../../actions';
import { PRODUCT_IMG_URL } from '../../services/config';
import { QtdField, Loading } from '../../components';
import { searchProductService, productDetailsService } from '../../services/productService';
import { productModel } from '../../models';

import no_photo from '../../assets/imgs/no-photo.png';
import './productdetails.css';


const ProductDetails = ({
  productList,
  order,
  match: { params: { productId } },
  addItem,
  history
}) => {

  const orderList = order.list || [];

  const { deliveryDate, eventDate, withdrawal } = order;


  const orderItem = orderList.find(elem => elem.id + '' === productId + '');
  const initialQtd = orderItem ? orderItem.qtd : 0;

  const [product, setProduct] = useState(productModel(productList.find(elem => elem.id + '' === productId + '')));
  const [msg, setMsg] = useState('Buscando...');
  const [qtd, setQtd] = useState(initialQtd);
  const [sts, setSts] = useState('loading');

  // console.log('orderList3333', product, productId, order)

  useEffect(() => {
    if (!product) {
      searchProductService({ id: productId })
        .then(ret => {
          if (!ret || !ret.length) {
            return setMsg('Product não encontrado!')
          }
          setProduct(productModel(ret[0]));
        })
    }
    productDetailsService({
      produtoId: productId,
      dataSaida: deliveryDate,
      dataRetorno: withdrawal
    })
      .then(ret => {
        // console.log('estoque infos', ret);
        setProduct(productModel({ ...product, ...ret }));
        setSts('');
      });
  }, []);

  const onChange = (fnc) => {
    return ({ target: { value } }) => {
      return fnc(value);
    }
  }

  const onAddItem = () => {
    addItem(product, qtd);
    history.push('/order');
  }

  let productHtml = <h2>{msg}</h2>;
  if (product) {
    // const { id, name, qtdAloc, qtdBloq, qtdDisp, stock } = product;
    const { name, qtdDisp } = product;
    let { img } = product;
    img = img ? PRODUCT_IMG_URL + img : no_photo;
    productHtml = (
      <div>
      <div className="imgContainer">
        <img className="product-details-img" src={img} alt={name} />
          <div className="bottom-right">{name}</div>
      </div>
        <div className="product-details1">
          <Loading status={sts} />
          <div className="container-padding">
            <p>QUANTIDADE EM ESTOQUE</p>
            <p className="color-ritz">{product.stock()}</p>
          </div>
          <div className="container-padding">
            <p>QUANTIDADE DISPONÍVEL</p>
            <p className="color-ritz">{qtdDisp}</p>
          </div>
          <div className="product-details-controls container-padding">
            <QtdField onChange={onChange(setQtd)} value={qtd} max={qtdDisp} />
            <button className="btn-default" onClick={onAddItem} type="button">Adicionar</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="products-details color-ritz">
      {productHtml}
    </div>
  )
}

const mapState2Props = state => ({
  productList: state.products.list,
  order: state.order,
});

const mapDispatch2Props = dispatch => ({
  addItem: (product, qtd) => dispatch(addOrderItem(product, qtd))
})

export default connect(mapState2Props, mapDispatch2Props)(ProductDetails);