import React from 'react';

import './loading.css';

const Loading=({status})=>{
  if(status !== 'loading') {
    return false;
  }
  return (
    <div className="loading">
      <p><i className="icon-spin6 animate-spin"></i></p>
    </div>
  )
}

export default Loading;