import React from 'react';
import {connect} from 'react-redux';

import {SlideMsg} from '../';

import './header.css';

import logo_ritz from '../../assets/imgs/ritz-logo1.png';

const Header=(props)=>{
  const {auth,history,order,location:{pathname}} = props;
  const {authData}=auth;

  if(!authData.isLogged()) {
    return false;
  }

  let title='Página não encontrada!';
  // pathname x title
  if(pathname === '/home' || pathname === '/') {
    title='Bem vindo ao RITZ';
  } else if(pathname === '/search') {
    title='Busca de produtos';
  } else if(pathname === '/order') {
    title='Consulta Cotação';
  } else if(pathname === '/order-finished') {
    title='Finalização de Pedido';
  } else if(pathname.indexOf('/product-details') > -1) {
    title='Detalhes do produto';
  }

  const logout=()=>{
    order.clearOrderData();
    authData.doLogout();
    history.push('/');
  }

  return (
    <header className="header container">
      <SlideMsg />
      <img src={logo_ritz} alt="Logo Ritz" />
      <h3 className="color-ritz">{title}</h3>
      <i className="icon-logout color-ritz" onClick={logout}></i>
    </header>
  )
}

const mapState2Props=(state)=>({
  auth:state.auth,
  order:state.order,
})

export default connect(mapState2Props)(Header);