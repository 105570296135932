
/*
ritz-order-system: {

  clientTypeList: {
    loadedAt: timestamp
    list: []
  },

  authData: {
    "user_id": 4,
    "name": "Alexandre Notte",
    "username": "use01",
    "email": "email@email.com",
    "tipo_usuario": "Gestor",
    "perfil_de_acesso": 3,
    "token": "string token here!"
  }
}
*/

const ritzStorageItem = 'ritz-order-system';


const saveLocalStorage = (data) => {

  const savedData = loadLocalStorage();

  const toSave = {
    ...savedData,
    ...data
  }

  localStorage.setItem(ritzStorageItem, JSON.stringify(toSave));
}

const loadLocalStorage = () => {
  const defaultObj = {
    auth: {},
    clientTypeList: {},
    order: {}
  }
  let savedData = localStorage.getItem(ritzStorageItem);
  savedData = savedData ? JSON.parse(savedData) : {};


  return {
    ...defaultObj,
    ...savedData
  }

}

export {
  saveLocalStorage,
  loadLocalStorage
}