// GLOBAL
export const SET_GLOBAL_MSG = 'SET_GLOBAL_MSG';


// AUTH
// export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
// export const SET_AUTH_MSG = 'SET_AUTH_MSG';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';

// CLIENT
// export const SET_CLIENT_STATUS = 'SET_CLIENT_STATUS';
// export const SET_CLIENT_MSG = 'SET_CLIENT_MSG';
export const SET_CLIENT = 'SET_CLIENT';


// PRODUCTS
export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const ADD_PRODUCT = 'ADD_PRODUCT';



// ORDER
// export const SET_ORDER_MSG = 'SET_ORDER_MSG';
// export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_ORDER_ITEM_QTD = 'SET_ORDER_ITEM_QTD';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';
export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
// extra data - dataEvento, dataSaida, dataRetorno
export const SET_ORDER_DATA = 'SET_ORDER_DATA';


