import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { setOrderExtraData } from '../../actions';
import {
  HeaderRitz,
  TextField,
  Loading,
  DateTimePickerField,
  ClientType
} from '../../components';

import './home.css';

const Home = (props) => {
  const { auth, order, save, history } = props;

  const clientSaved = order.client ? order.client : '';
  const eventDateSaved = order.eventDate ? moment(order.eventDate).toDate() : null;
  const deliveryDateSaved = order.deliveryDate ? moment(order.deliveryDate).toDate() : null;
  const withdrawalSaved = order.withdrawal ? moment(order.withdrawal).toDate() : null;
  const clientTypeSaved = order.clientType ? order.clientType : '';

  const { authData } = auth;

  // const [attendant, setAttendant] = useState({ val: '', error: '' });
  const [client, setClient] = useState({ val: clientSaved, error: '' });
  const [eventDate, setEventDate] = useState({ val: eventDateSaved, error: '' });
  const [deliveryDate, setDeliveryDate] = useState({ val: deliveryDateSaved, error: '' });
  const [withdrawal, setWithdrawal] = useState({ val: withdrawalSaved, error: '' });
  const [clientType, setClientType] = useState({ val: clientTypeSaved, error: '' });

  const [submitBtnLabel,setSubmitBtnLabel] = useState('Novo Orçamento');

  useEffect(()=>{
    if(order.isValid()) {
      setSubmitBtnLabel('Atualizar Orçamento');
    }
  },[]);

  const onChange = handler => (val) => {
    const value = val.target ? val.target.value : val;
    return handler({ val: value, error: '' });
  }

  const onSubmit = (e) => {
    e.preventDefault();

    client.error = '';
    eventDate.error = '';
    deliveryDate.error = '';
    withdrawal.error = '';
    clientType.error = '';

    let ok = true;
    if (!client.val) {
      client.error = 'Digite um cliente!';
      ok = false;
    }
    if (!eventDate.val) {
      eventDate.error = 'Forneça a data do evento!';
      ok = false;
    }
    if (!deliveryDate.val) {
      deliveryDate.error = 'Forneça a data de entrega!';
      ok = false;
    }
    if (!withdrawal.val) {
      withdrawal.error = 'Forneça a data de retirada!';
      ok = false;
    }
    if (clientType.val < 0) {
      clientType.error = 'Escolha um tipo de cliente!';
      ok = false;
    }

    // comparando datas
    const eventDateMt = moment(eventDate.val);
    const deliveryDateMt = moment(deliveryDate.val);
    const withdrawalMt = moment(withdrawal.val);

    if (ok) {
      if (deliveryDateMt > eventDateMt) {
        deliveryDate.error = 'A data de entrega não pode ser superior à data do evento!';
        ok = false;
      }
      if (deliveryDateMt > withdrawalMt) {
        deliveryDate.error = 'A data de entrega não pode ser superior à data de retirada!';
        ok = false;
      }
      if (eventDateMt > withdrawalMt) {
        eventDate.error = 'A data do evento não pode ser superior à data de retirada!';
        ok = false;
      }
    }

    if (!ok) {
      setClient({ ...client });
      setEventDate({ ...eventDate });
      setDeliveryDate({ ...deliveryDate });
      setWithdrawal({ ...withdrawal });
      setClientType({ ...clientType });
    } else {
      const format = 'YYYY-MM-DD HH:mm:ss';
      const dataObj = {
        client: client.val,
        eventDate: eventDateMt.format(format),
        deliveryDate: deliveryDateMt.format(format),
        withdrawal: withdrawalMt.format(format),
        clientType: clientType.val,
      }
      save(dataObj);
      // console.log('dataObj', dataObj);
      history.push('/search');
    }
  }

  const fieldClasses = ['form-group'];

  const status = '';
  return (
    <div className="home container color-ritz">
      <div className="sub-container">
        <HeaderRitz />
        <h3>Preencha os dados do novo pedido</h3>
        <form onSubmit={onSubmit}>
          <Loading status={status} />
          <TextField classes={fieldClasses} type="text" label="Atendende logado" readOnly placeholder="Atendente Logado" value={authData.name} />
          <TextField classes={fieldClasses} type="text" label="Nome do cliente" onChange={onChange(setClient)} error={client.error} placeholder="Nome do cliente" value={client.val} />
          <DateTimePickerField classes={fieldClasses} label="Data/hora do evento" onChange={onChange(setEventDate)} value={eventDate.val} error={eventDate.error} />
          <DateTimePickerField classes={fieldClasses} label="Data/hora da entrega" onChange={onChange(setDeliveryDate)} value={deliveryDate.val} error={deliveryDate.error} />
          <DateTimePickerField classes={fieldClasses} label="Data/hora da retirada" onChange={onChange(setWithdrawal)} value={withdrawal.val} error={withdrawal.error} />
          {/* <TextField label="Tipo de cliente" onChange={onChange(setClientType)} error={clientType.error} placeholder="Tipo cliente" value={clientType.val} /> */}
          <div id="tipo_cliente_field" className={fieldClasses.join(' ')}>
            <label className="label">Tipo de Cliente</label>
            <ClientType onChange={onChange(setClientType)} value={clientType.val} />
          </div>
          <button type="submit" className="btn-default">{submitBtnLabel}</button>
        </form>
      </div>
    </div>
  )
}

const mapState2Props = (state) => ({
  auth: state.auth,
  order: state.order
});

const mapDispatch2Props = (dispatch) => ({
  save: (extraData) => dispatch(setOrderExtraData(extraData))
})

export default connect(mapState2Props, mapDispatch2Props)(Home);
