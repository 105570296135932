import React from 'react';

import logo from '../../assets/imgs/ritz-logo1.png';
import logo_escrito from '../../assets/imgs/ritz-logo-escrito.png';

import './footerritz.css';

const FooterRitz = () => {

  return (
    <footer id="footer" className="footer">
      <div>
        <p>
          <strong>App Ritz</strong> by <a href="http://www.ewti.com.br">Engenharia de Web</a>
        </p>
      </div>
    </footer>
  )
}

export default FooterRitz;