import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, withRouter, Switch } from 'react-router-dom';

import { PRODUCT_BASE_URL } from './services/config';
import Root from './Root';

// Components
import { Header, PrivateRoute, Menu } from './components';

// Screens
import {
  Login,
  Home,
  ProductList,
  NoMatch,
  ProductDetails,
  OrderList,
  OrderFinished
} from './screens';

// CSS
import './fontello/css/animation.css';
import './fontello/css/fontello.css';
import './index.css';



const HeaderWithRouter = withRouter(Header);
const MenuWithRouter = withRouter(Menu);
const rootApp = (
  <Root>
    <BrowserRouter basename="/">
      <>
      <HeaderWithRouter />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/search" component={ProductList} />
        <PrivateRoute path={`${PRODUCT_BASE_URL}/:productId`} component={ProductDetails} />
        <PrivateRoute path="/order" component={OrderList} />
        <PrivateRoute path="/order-finished" component={OrderFinished} />
        <Route component={NoMatch} />
      </Switch>
      <MenuWithRouter />
      </>
    </BrowserRouter>
  </Root>
)

ReactDOM.render(rootApp, document.querySelector('#root'));