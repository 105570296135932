import axios from 'axios';

import { authDataModel } from '../models';
import { API_BASE_URL } from './config';

export const parseAxiosError = (error) => {
  let errMsg = 'Houve um erro indefinido!';
  // console.log(JSON.stringify(error));
  if (error.response) {

    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    errMsg = error.response.data.message ? error.response.data.message : error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    errMsg = 'Houve um erro ao fazer a requisição!';
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    errMsg = error.message;
  }

  return errMsg;
}

export default () => {
  // const authorization=process.env.REACT_APP_AUTHORIZATION;
  const authData=authDataModel();
  const authorization=authData.token;
  try {

    const instance = axios.create({
      baseURL: API_BASE_URL,
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization
      }
    });
    return instance;

  } catch (error) {

  }

}
