import conn, { parseAxiosError } from './conn';

const doLoginService = async ({user, pass}) => {
  if (!user || !pass) {
    throw new Error('Forneça um login e uma senha válidos!');
  }

  try {
    const { data } = await conn().post(`/login`,{user, pass});
    return data;
  } catch (e) {
    const errMsg = parseAxiosError(e);
    throw new Error(errMsg);
  }
}

export {
  doLoginService
}