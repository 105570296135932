import React from 'react';
import {connect} from 'react-redux';

import './orderfinished.css';

const OrderFinished=({order})=>{

  let msg;
  if(!order.list.length) {
    msg=<h3 className="default-msg1">Não há pedido em andamento!</h3>
  } else {
    order.clearOrderData();
    msg=<h1 className="order-finished-success">Pedido finalizado com sucesso!</h1>
  }

  return (
    <div className="order-finished">
      {msg}
    </div>
  )
}

const mapState2Props=state=>({
  order:state.order
})

export default connect(mapState2Props)(OrderFinished);