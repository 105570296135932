import conn, { parseAxiosError } from './conn';

const createOrder = async ({order}={}) => {
  const {list}=order;
  if(!list || !list.length) {
    throw new Error('Não é permitido orçamento vazio! Escolha ao menos 1 item.');
  }

  try {
    const { data } = await conn().post(`/save-order`,{order});

    // data:
    // { insertId: 49617, affectedRows: 1 }
    return data;
  } catch (e) {
    const errMsg = parseAxiosError(e);
    throw new Error(errMsg);
  }
}

export {
  createOrder
}