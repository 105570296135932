import React from 'react';
import {Link} from 'react-router-dom';
import {PRODUCT_IMG_URL, PRODUCT_BASE_URL} from '../../services/config';
import no_photo from '../../assets/imgs/no-photo.png';

import './productlistitem.css';

const ProductListItem=({product})=>{
  const {id,name}=product;
  let {img}=product;
  if(!img) {
    img=no_photo;
  } else {
    img=PRODUCT_IMG_URL+img;
  }

  return (
    <div className="product-list-item">
      <div className="left-item">
        <img src={img} alt="Item" />
        <p>{name}</p>
      </div>
      <div className="right-item">
        <Link to={`${PRODUCT_BASE_URL}/${id}`} className="btn-default">Detalhes</Link>
      </div>
    </div>
  )
}


export default ProductListItem;