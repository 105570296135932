import {
  SET_ORDER_LIST,
  SET_ORDER_ITEM_QTD,
  REMOVE_ORDER_ITEM,
  ADD_ORDER_ITEM,
  SET_ORDER_DATA
} from './actions';
// import { setGlobalMsg } from './global';

const setOrderList = (list) => {
  return {
    type: SET_ORDER_LIST,
    list
  }
}

const setOrderItemQtd = (id, qtd) => {
  return {
    type: SET_ORDER_ITEM_QTD,
    id,
    qtd
  }
}

const removeOrderItem = (id) => {
  return {
    type: REMOVE_ORDER_ITEM,
    id
  }
}

const addOrderItem = (product, qtd) => {
  return {
    type: ADD_ORDER_ITEM,
    product,
    qtd
  }
}

const setOrderExtraData = ({client,eventDate,deliveryDate,withdrawal,clientType}) => {
  return {
    type: SET_ORDER_DATA,
    client,
    eventDate,
    deliveryDate,
    withdrawal,
    clientType
  }
}



export {
  setOrderList,
  setOrderItemQtd,
  removeOrderItem,
  addOrderItem,
  setOrderExtraData
}
