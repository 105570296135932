import React from 'react';
import pt_BR from 'date-fns/locale/pt-BR';
import DateTimePicker,{ registerLocale } from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import './datetimepickerfield.css';

registerLocale('pt-BR', pt_BR);

const DateTimePickerField = (props) => {
  const { onChange, value, label, error='', classes=[], ...rest } = props;

  const labelHtml = label ? <label className="label">{label}</label> : false;

  const errorHtml = error ? <small className="default-error-msg">{error}</small> : false;

  // Preventing keyboard mobile
  const onFocus=e=>e.target.readOnly = true

  const classes2=classes.concat(['datetime-picker-field']).join(' ');

  return (
    <div className={classes2}>
      {labelHtml}
      <DateTimePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        timeCaption="time"
        dateFormat="dd/MM/yyyy HH:mm"
        locale="pt-BR"
        onFocus={onFocus}
        {...rest}
      />
      {errorHtml}
    </div>
  )

}

export default DateTimePickerField;