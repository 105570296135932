import conn, { parseAxiosError } from './conn';
import { saveLocalStorage, loadLocalStorage } from './localStorage';


const clientTypeService = async () => {
  try {
    const { data } = await conn().get(`/tipo-parceiro`);
    return data;
  } catch (e) {
    const errMsg = parseAxiosError(e);
    throw new Error(errMsg);
  }
  // localhost:3002/busca-produto?q=tulipa
}

const getClientTypeService = async () => {
  const { clientTypeList: { loadedAt: loadedAtSaved, list: listSaved } } = loadLocalStorage();
  const timeout = 4 * 60 * 60;
  const now = (Date.now()) / 1000;
  const diff = now - (+loadedAtSaved);

  let list;

  if (!listSaved || (diff > timeout)) {
    // reload list
    list = await clientTypeService();
    list=list.map(elem=>({value:elem.ID,label:elem.DESCR}));
    const toSave = {
      clientTypeList: {
        loadedAt: Date.now(),
        list
      }
    }
    saveLocalStorage(toSave);
  } else {
    list = listSaved;
  }
  return list;
}

export {
  clientTypeService,
  getClientTypeService
}