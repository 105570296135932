import {
  SET_ORDER_LIST,
  SET_ORDER_ITEM_QTD,
  REMOVE_ORDER_ITEM,
  ADD_ORDER_ITEM,
  SET_ORDER_DATA
} from '../actions/actions';

import { orderModel, orderItemModel } from '../models';


// const initialState = {
//   list: [],
//   client: '',
//   clientType: '',
//   dataEvento: '',
//   dataSaida: '',
//   dataRetorno: ''
// }

const initialState = orderModel();


export default (state = initialState, action) => {
  const { type } = action;

  if (type === SET_ORDER_LIST) {
    return { ...state, msg: '', status: '', list: action.list.map(elem => orderItemModel(elem)) }
  } else if (type === SET_ORDER_ITEM_QTD) {
    const { id, qtd } = action;
    const newList = state.list.map(elem => {
      if (elem.id === id) {
        elem.qtd = qtd;
      }
      return orderItemModel(elem);
    });
    return orderModel({ ...state, list: newList })
  } else if (type === REMOVE_ORDER_ITEM) {
    const { id } = action;
    const newList = state.list.filter(elem => elem.id !== id);
    return orderModel({ ...state, list: newList });
  } else if (type === ADD_ORDER_ITEM) {
    const { product, qtd } = action;
    let newList;
    // already exist?
    if (state.list.findIndex(elem => elem.id === product.id) > -1) {
      newList = state.list.map(elem => {
        if (elem.id === product.id) {
          elem.qtd = qtd;
        }
        return orderItemModel(elem);
      });
    } else {
      newList = [...state.list, orderItemModel({ ...product, qtd })]
    }
    return orderModel({ ...state, list: newList });
  } else if (type === SET_ORDER_DATA) {
    const arrData = ['client','eventDate','deliveryDate','withdrawal','clientType'];
    const updatingObj = {};
    arrData.forEach(elem => {
      if (action[elem] !== undefined) {
        updatingObj[elem] = action[elem];
      }
    });

    return orderModel({ ...state, ...updatingObj });
  }
  return state;
}