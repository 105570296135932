import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './menu.css';

const Menu = (props) => {
  const { auth: { authData }, location: { pathname }, order } = props;

  if (!authData.isLogged()) {
    return false;
  }
  let cover=false;
  if(pathname === '/home' && !order.isValid()) {
    cover=<div className="cover-menu" />
  }

  return (
    <div className="menu background-ritz">
      {cover}
      <ul>
        <li><Link to="/home"><i className="icon-home"></i></Link></li>
        <li><Link to="/search"><i className="icon-plus-circled"></i></Link></li>
        <li><Link to="/order"><i className="icon-shopping-basket"></i></Link></li>
      </ul>
    </div>
  )
}

const mapState2Props = state => ({
  auth: state.auth,
  order: state.order
})

export default connect(mapState2Props)(Menu);