import React from 'react';

import './textfield.css';

const TextField = React.forwardRef(({ error='', classes = [], label='', ...rest },ref) => {

  classes = classes.concat('text-field').join(' ');

  const errorHtml = error ? <small className="default-error-msg">{error}</small> : false;

  const labelHtml = label ? <label className="label">{label}</label> : false;

  return (
    <div className={classes}>
      {labelHtml}
      <input type="text" className="default-text-field" {...rest} ref={ref} />
      {errorHtml}
    </div>
  )
})

export default TextField;