import React from 'react';
import { connect } from 'react-redux';
import { setOrderItemQtd, removeOrderItem, setGlobalMsg } from '../../actions';
import { OrderListItem, Loading } from '../../components';
import { createOrder } from '../../services/orderService';

import './orderlist.css';

const OrderList = ({
  order,
  setQtd,
  removeItem,
  status,
  setGlobalMsgFnc,
  history
}) => {

  const saveOrder = async () => {
    // createOrder
    try {

      setGlobalMsgFnc('','loading');
      const newOrder = await createOrder({order});
      setGlobalMsgFnc('','');
      history.push("/order-finished");
      return newOrder;
    } catch(e) {
      setGlobalMsgFnc(e.message, 'error');
    }
  }

  const { list } = order;
  let content, finish;
  if (list.length) {
    content = list.map(elem => <OrderListItem key={elem.id} item={elem} onChangeQtd={setQtd} onRemove={removeItem} />);
    finish = (
      <div className="finish-order container-padding">
        <button onClick={saveOrder} type="button" className="btn-default" >Finalizar Pedido</button>
      </div>
    );
  } else {
    content = <h3 className="default-msg1">O carrinho está vazio!</h3>;
    finish = false;
  }

  return (
    <div className="order-list color-ritz padding-menu-bottom">
      <Loading status={status} />
      {content}
      {finish}
    </div>
  )
}

const mapState2Props = state => ({
  order: state.order,
  status: state.global.status,
});

const mapDispatch2Props = dispatch => ({
  setQtd: (id, qtd) => dispatch(setOrderItemQtd(id, qtd)),
  removeItem: (id) => dispatch(removeOrderItem(id)),
  setGlobalMsgFnc: (msg,status) => dispatch(setGlobalMsg(msg,status))
});

export default connect(mapState2Props, mapDispatch2Props)(OrderList);