import { SET_GLOBAL_MSG } from './actions';

const setGlobalMsg = (msg,status='') => {
  return {
    type: SET_GLOBAL_MSG,
    msg,
    status
  }
}

export {
  setGlobalMsg
}