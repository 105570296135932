import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { setGlobalMsg } from '../../actions';

import './slidemsg.css';

const SlideMsg = ({ msg, setMsg }) => {
  const active = msg && msg !== 'loading' ? ' show' : '';

  const onClick = () => setMsg('');

  const element = (
    <>
    <div className={"slide-msg-background" + active}></div>
    <div className={"slide-msg border-ritz" + active}>
      <h3>{msg}</h3>
      <button type="button" onClick={onClick} className="btn-default">Ok</button>
    </div>
    </>
  );
  document.scrollingElement.scrollTop=0;
  return ReactDOM.createPortal(element, document.querySelector('#root'));
}

const mapState2Props = ({ global }) => ({
  msg: global.msg
});

const mapDispatch2Props = dispatch => ({
  setMsg: (msg) => dispatch(setGlobalMsg(msg))
})

export default connect(mapState2Props, mapDispatch2Props)(SlideMsg);