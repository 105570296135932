import React, { useState } from 'react';
import { connect } from 'react-redux';
import { validate } from 'email-validator';
import { Redirect } from "react-router-dom";
import { startLogin } from '../../actions';

import {
  TextField,
  Loading,
  HeaderRitz,
  FooterRitz,
  SlideMsg
} from '../../components';

import './login.css';

const Login = ({ status, authData, login, history }) => {
  const user2=process.env.REACT_APP_RITZ_USER || '';
  const pass2=process.env.REACT_APP_RITZ_PASS || '';

  const [user, setUser] = useState({ val: user2, error: '' });
  const [pass, setPass] = useState({ val: pass2, error: '' });


  const onChange = handler => ({ target }) => {
    return handler({ val: target.value })
  }

  // isLogged
  if(authData.isLogged()) {
    return <Redirect to="/home" />
  }

  const doLogin = (e) => {
    e.preventDefault();

    if (!user.val) {
      user.error = 'Digite um email válido!';
    } else {
      user.error = '';
    }

    if (!pass.val) {
      pass.error = 'Digite um password!';
    } else {
      pass.error = '';
    }

    if (user.error || pass.error) {
      setUser({ ...user });
      setPass({ ...pass });
    } else {
      return login(user.val, pass.val)
      .then(()=>{
        return history.push('/home');
      })
      .catch(()=>false);
    }
  }

  return (
    <main className="login">
      <HeaderRitz />
      <h1>Aplicativo Consulta Cotação</h1>
      <SlideMsg />
      <form onSubmit={doLogin} className="login-form">
        <Loading status={status} />
        <TextField type="text" onChange={onChange(setUser)} error={user.error} placeholder="Usuário" value={user.val} />
        <TextField type="password" onChange={onChange(setPass)} error={pass.error} placeholder="Senha" value={pass.val} />
        <br></br>
        <button type="submit" className="btn-default">Login</button>
      </form>
      <FooterRitz />
    </main>
  )
}

const mapState2Props = ({auth,global}) => ({
  status: global.status,
  authData: auth.authData
})

const mapDispatch2Props = (dispatch) => {
  return {
    login: (user, pass) => dispatch(startLogin(user, pass))
  }
}

export default connect(mapState2Props, mapDispatch2Props)(Login);