import { SET_CLIENT } from '../actions/actions';

import { clientModel } from '../models';

const initialState = {
  client: clientModel(),
  status: '',
  msg: ''
}


export default (state = initialState, action) => {
  const { type } = action;

  if (type === SET_CLIENT) {
    return { ...state, client: clientModel(action.client), status: '', msg: '' }
  } else {
    return state;
  }
}