import { SET_AUTH_DATA } from '../actions/actions';

import { authDataModel } from '../models';

const initialState = {
  authData: authDataModel(),
  status: '',
  msg: ''
}


export default (state = initialState, action) => {
  const { type } = action;
  if (type === SET_AUTH_DATA) {
    return {
      ...state,
      authData: authDataModel(action.authData),
      status: '',
      msg: ''
    }
  } else {
    return state;
  }
}