/*
client: {
  client
  clientType
}
*/

export default (clientData={}) => {
  const {
    client='',
    clientType='',
  } = clientData;

  return {
    client,
    clientType
  }

}