import conn, { parseAxiosError } from './conn';

const searchProductService = async ({ descr = '', id='' } = {}) => {

  if (descr.length < 3 && ! id) {
    throw new Error('Digite ao menos 3 caracteres para realizar uma busca!');
  }

  try {
    const { data } = await conn().get(`/busca-produto?q=${descr}&id=${id}`);
    return data;
  } catch (e) {
    const errMsg = parseAxiosError(e);
    throw new Error(errMsg);
  }
  // localhost:3002/busca-produto?q=tulipa
}

/*
dataSaida: data menor - retirada de prods
dataRetorno: data maior - entrega de prods de volta à Ritz
*/
const productDetailsService = async ({ produtoId, dataSaida, dataRetorno, pedidoId=0 }) => {
  if(!produtoId || !dataSaida || !dataRetorno) {
    throw new Error('Forneça um ID de produto, uma data de saída e uma data de retorno!');
  }
  try {
    // 
    const url=`/produto-detalhes?produtoId=${produtoId}&dataSaida=${dataSaida}&dataRetorno=${dataRetorno}`;
    const { data } = await conn().get(url);
    return data;
    
  } catch(e) {
    const errMsg = parseAxiosError(e);
    throw new Error(errMsg);
  }
}

export {
  searchProductService,
  productDetailsService
}
