import React from 'react';

import './qtdfield.css';

const QtdField = ({ onChange, value = 0, min=0, max=0 }) => {

  const cmd = (oper) => {

    return () => {
      let v = +value;
      if (oper === '+') {
        v++;
      } else {
        v--;
      }
      if(v < min) v=min;
      if(v > max) v=max;
      return onChange({ target: { value: v } });
    }
  }

  const onChangeWrapper = (e) => {
    let {target:{value}}=e;
    if(value < min) value=min;
    if(value > max) value=max;
    e.target.value=value;
    return onChange(e);
  }

  return (
    <div className='qtd-field'>
      <span onClick={cmd('-')}><i className="icon-minus"></i></span>
      <input type="number" onChange={onChangeWrapper} value={value} max={max} />
      <span onClick={cmd('+')}><i className="icon-plus"></i></span>
    </div>
  )
}

export default QtdField;