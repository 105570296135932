import { SET_GLOBAL_MSG } from '../actions/actions';
const initialState = {
  msg: '',
  status: ''
}

export default (state = initialState, action) => {
  const { type, msg, status } = action;

  if (type === SET_GLOBAL_MSG) {
    return { ...state, msg, status }
  }

  return state;
}