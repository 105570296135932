/*
product:{
  name:'',
  stock: ##,
  available: ##,
  img:''
}

qtdAloc: 0
qtdBloq: 0
qtdDisp: 113

*/


export default (productData)=>{
  if(!productData) {
    return false;
  }
  const {id,name,qtdAloc,qtdBloq,qtdDisp,img}=productData;

  return {
    id,
    name,
    qtdAloc:+qtdAloc || 0,
    qtdBloq:+qtdBloq || 0,
    qtdDisp:+qtdDisp || 0,
    img,
    stock:function() {
      const {qtdAloc, qtdBloq, qtdDisp}=this;
      return qtdAloc + qtdBloq + qtdDisp
    }
  }
}