import React, { useState, useEffect } from 'react';
import { getClientTypeService } from '../../services/clientTypeService';

import './clienttype.css';

const ClientType = ({extraClasses=[],...rest}) => {

  const emptyList=[{value:-1,label:'loading'}];
  const [list,setList]=useState(emptyList);

  useEffect(()=>{
    getClientTypeService().then(ret=>{
      setList(ret);
    });
  },[]);

  const options=list.map(({value,label})=><option key={value} value={+value}>{label}</option>);

  const classes = ['client-type'].concat(extraClasses).join(' ');

  return (
    <div className={classes}>
      <select {...rest} className="select-default">{options}</select>
    </div>
  )
}



export default ClientType;