import { SET_PRODUCTS_LIST, ADD_PRODUCT } from './actions';
import { searchProductService } from '../services/productService';
import { productModel } from '../models';

import { setGlobalMsg } from './global';

const setProductsList = (list) => {
  return {
    type: SET_PRODUCTS_LIST,
    list
  }
}

const searchProduct = ({descr,id}) => {
  return async dispatch => {
    dispatch(setGlobalMsg('','loading'));
    dispatch(setProductsList([]));

    try {

      let products = await searchProductService({ descr, id });
      products = products.map(elem => productModel(elem));
      dispatch(setGlobalMsg(''));
      return dispatch(setProductsList(products));
    } catch (e) {
      return dispatch(setGlobalMsg(e.message, 'error'))
    }
  }
}


export {
  setProductsList,
  searchProduct
}